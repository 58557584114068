<template>
  <v-slide-y-reverse-transition>
    <v-card class="base">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="8">
            <v-card class="elevation-3 mb-10" max-height="90%">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>タイプ診断</v-toolbar-title>
              </v-toolbar>
              <v-list-item>
                <v-card-title
                  >あなたに適した電気自動車の利用方法を考えるために、以下の質問に答えてください</v-card-title
                >
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-card-title
                  >お住まいの形態について教えてください</v-card-title
                >
              </v-list-item>
              <v-form v-model="valid">
                <v-list-item class="pl-10">
                  <v-radio-group v-model="houseInfo" row :rules="[required]">
                    <v-radio label="一戸建て・所有" value="house"></v-radio>
                    <v-radio label="集合住宅・所有" value="apartment"></v-radio>
                    <v-radio label="賃貸" value="rent"></v-radio>
                  </v-radio-group>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-card-title
                    >一度にどのくらいの距離を運転されますか</v-card-title
                  >
                </v-list-item>
                <v-list-item class="pl-10">
                  <v-radio-group v-model="distanceInfo" row :rules="[required]">
                    <v-radio label="100km以上" value="over100km"></v-radio>
                    <v-radio label="100km未満" value="under100km"></v-radio>
                    <v-radio label="50km未満" value="under50km"></v-radio>
                    <v-radio label="20km未満" value="under20km"></v-radio>
                  </v-radio-group>
                </v-list-item>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    :disabled="!valid || typeSummary != ''"
                    @click="checkType"
                    :loading="loading"
                    >診断する
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-form>

              <v-list-item>
                <v-card-title>診断結果は？</v-card-title>
              </v-list-item>
              <!-- <v-list-item class="pa-10">
            <v-img v-bind:src="typePicture"></v-img>
          </v-list-item>-->
              <v-list-item>
                <v-card-title class="font-weight-bold">{{
                  typeSummary
                }}</v-card-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="mx-7 mb-3">{{
                  typeDescription
                }}</v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-slide-y-reverse-transition>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/event.js";

export default {
  data() {
    return {
      houseInfo: "",
      distanceInfo: "",
      typeSummary: "",
      typeDescription: "",
      valid: false,
      required: (value) => !!value || "必ず入力が必要です",
      loading: false,
    };
  },
  methods: {
    checkType() {
      this.$gtag.event("check", {
        event_category: "checkType",
        event_label: `${this.houseInfo} ${this.distanceInfo}`,
      });
      // API call
      this.loading = true;
      const method = "post";
      const url = `${process.env.VUE_APP_SEARCH_API_ENDPOINT}/questionnaire`;
      const houseInfo = this.houseInfo;
      const distanceInfo = this.distanceInfo;
      const data = {
        houseInfo,
        distanceInfo,
      };
      axios({
        method,
        url,
        data,
      }).then((response, err) => {
        if (err) {
          EventBus.$commit("error", "エラーが発生しました");
          this.loading = false;
        } else {
          this.loading = false;
          this.typeSummary = response.data.typeSummary;
          this.typeDescription = response.data.typeDescription;
        }
      });
    },
  },
  metaInfo: {
    title: "診断",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "電気自動車の充電計画と言われてもよくわからない。なくなったら充電すればいいのでは？そんなあなたはどのように充電器を探したらいいか、診断をうけましょう。",
      },
    ],
  },
};
</script>

<style scoped>
.base {
  transition-duration: 0.15s;
  position: absolute;
  top: 0;
  transition-property: all;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>